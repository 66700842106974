import { useMemo, useState, useEffect, useRef } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { useLocation } from "react-router-dom";
import { Link, useTheme } from "@smartrent/ui";
import { Hamburger, Search } from "@smartrent/icons";

import { useClickOutside } from "@smartrent/hooks";

import { NineSquareButton } from "@smartrent/central-station-ui";

import { NavLinkProps } from "@/react/types";
import { useCurrentUser } from "@/react/context/mgmt-api/user";
import { logout } from "@/react/queries/mgmt-api/auth";
import { can, PermissionType } from "@/react/hooks/permissions";
import { useCanViewMyAccess } from "@/react/hooks/my-access";
import {
  users,
  organization,
  region,
  profile,
  support as supportLinks,
} from "@/lib/links";
import { useGetConfiguration } from "@/react/queries/mgmt-api/configuration";
import { useOrganization } from "@/react/queries/mgmt-api/organization";
import * as links from "@/lib/links";
import { permissions } from "@/react/common/utils/permissions";
import { getUrlWithoutSupportMode } from "@/react/common/utils";

import { useFeatureFlagEnabled } from "@/react/queries/mgmt-api/feature-flags";

import {
  fetchApps,
  onPressApp,
} from "@/react/queries/mgmt-api/my-applications";

import { GlobalSearch, useGlobalSearch } from "../GlobalSearch/GlobalSearch";

export const NavItems = ({
  isMobile,
  showMobileNav,
}: {
  isMobile: boolean;
  showMobileNav: boolean;
}) => {
  const user = useCurrentUser();
  const location = useLocation();
  const { canView: canViewMyAccess } = useCanViewMyAccess();
  const [showHelp, setShowHelp] = useState(false);
  const [doLogout, setDoLogout] = useState(false);
  const { data: config } = useGetConfiguration();
  const { colors } = useTheme();

  const activeLinkStyles = isMobile
    ? [styles.mobileLink, { color: colors.brand500 }]
    : [styles.link, { color: colors.white }];

  const inactiveLinkStyles = isMobile
    ? [styles.mobileLink, { color: colors.gray800 }]
    : [styles.link, { color: colors.brand500 }];

  const helpRef = useRef(null);
  const helpMenuRef = useRef(null);

  useClickOutside({
    visible: showHelp,
    onClose: () => {
      setShowHelp(false);
    },
    refs: [helpRef, helpMenuRef],
  });

  // Prevents mashing the logout button and sending numerous requests
  useEffect(() => {
    if (doLogout) {
      logout();
    }
  }, [doLogout]);

  const settingsRoute = useMemo(() => {
    if (can(user, permissions.organizations.manage_profile_settings)) {
      return organization.settings();
    } else if (can(user, permissions.roles.manage)) {
      return organization.roles.index();
    } else if (can(user, permissions.users.view)) {
      return users.list();
    } else if (can(user, permissions.organizations.manage_regions)) {
      return region.index();
    } else if (can(user, permissions.organizations.manage_templates)) {
      return organization.templates.list();
    } else if (can(user, permissions.organizations.manage_credentials)) {
      return organization.credentials.list();
    } else if (can(user, permissions.organizations.manage_imports)) {
      return organization.imports();
    } else if (can(user, permissions.organizations.manage_ecommerce_settings)) {
      return organization.shop.settings();
    } else if (can(user, permissions.organizations.manage_tour_settings)) {
      return organization.tours();
    } else if (can(user, permissions.organizations.manage_webhooks)) {
      return organization.webhooks.list();
    } else if (can(user, permissions.community_defaults.update)) {
      return organization.communityDefaults.index();
    } else {
      return "";
    }
  }, [user]);

  const { data: currentOrganization } = useOrganization(null, {
    enabled: location.pathname.startsWith("/manager"),
  });
  const organizationId =
    currentOrganization !== undefined ? currentOrganization.id : null;
  const {
    data: { enabled: reportSetsEnabled },
  } = useFeatureFlagEnabled({
    flag: "report_sets",
    organizationId: organizationId,
  });

  const reportsRoute = useMemo(() => {
    if (
      !reportSetsEnabled &&
      can(
        user,
        [
          permissions.organization_reports.view,
          permissions.reports.manage_delivery_method,
        ],
        PermissionType.ANY
      )
    ) {
      return organization.reports.exports();
    } else if (
      reportSetsEnabled &&
      can(
        user,
        [
          permissions.reports.manage_organization_report_sets,
          permissions.reports.manage_personal_report_sets,
        ],
        PermissionType.ANY
      )
    ) {
      return links.dashboard.reportSets.view();
    } else if (can(user, permissions.work_orders.view)) {
      return organization.reports.assets();
    } else if (can(user, permissions.residents.view)) {
      return organization.reports.residents();
    } else if (can(user, permissions.tours.view_dashboard)) {
      return organization.reports.tours();
    } else {
      return "";
    }
  }, [user, reportSetsEnabled]);

  const navItems: NavLinkProps[] = useMemo(() => {
    const settingsTabActiveUris = [
      "/manager/organization-settings",
      "/manager/roles",
      "/manager/regions",
      "/manager/organization/templates",
      "/manager/organization/credentials",
      "/manager/imports",
      "/manager/organization/shops/settings",
      "/manager/organization/shops/products",
      "/manager/tours",
      "/manager/organization/community-defaults",
      "/manager/organization-settings/profile",
    ];

    return [
      {
        title: "Home",
        route: links.home,
        visible: can(user, permissions.properties.view),
      },
      {
        title: "Users",
        route: users.list(),
        visible: can(user, permissions.users.view),
        isActive: location.pathname.includes("/manager/users"),
      },
      {
        title: "Reports",
        route: reportsRoute,
        visible: reportsRoute !== "",
        isActive:
          location.pathname.includes("/dashboard") ||
          location.pathname.includes("/exports"),
      },
      {
        title: "My Access",
        route: organization.myAccess(),
        visible: canViewMyAccess,
      },
      {
        title: "Settings",
        route: settingsRoute,
        visible: settingsRoute !== "",
        isActive: settingsTabActiveUris.includes(location.pathname),
      },
      {
        title: "Account",
        route: profile.accountProfile(),
        visible: user ? !user.is_smartrent_staff : false,
        isActive: location.pathname.includes("/account"),
      },
      {
        title:
          user && user.is_smartrent_staff
            ? user.in_support_mode
              ? "Exit Support Mode"
              : user.is_support_staff
              ? "Organizations"
              : ""
            : "",
        fullPageReload: true,
        route:
          getUrlWithoutSupportMode() +
          supportLinks.smartRentStaff.organizations(),
        visible: user && user.is_smartrent_staff,
        isActive: false,
      },
    ]
      .filter(
        ({ visible, route, title }: NavLinkProps) =>
          visible !== false && !!route && !!title
      )
      .map(({ title, route, fullPageReload, isActive }: NavLinkProps) => ({
        title,
        isActive:
          isActive !== undefined
            ? isActive
            : location.pathname.startsWith(route),
        fullPageReload,
        route,
      }));
  }, [user, location.pathname, settingsRoute, reportsRoute, canViewMyAccess]);

  return (
    <ul
      className={`app__menu app-nav__menu ${
        isMobile ? "app-nav__menu-mobile" : "app-nav__menu-desktop"
      } ${showMobileNav ? "app-header__app-nav--visible" : ""}`}
    >
      {navItems.map((navItem) => {
        return (
          <li key={navItem.title} className="app-nav__menu__item">
            <div className="app-nav__menu__item__a">
              <Link
                style={navItem.isActive ? activeLinkStyles : inactiveLinkStyles}
                {...(navItem.fullPageReload || navItem.route.includes("http")
                  ? { href: navItem.route }
                  : { to: navItem.route })}
              >
                {navItem.title}
              </Link>
            </div>
          </li>
        );
      })}
      <li className="app-nav__menu__item">
        <div className="app-nav__menu__item__a">
          <Link style={inactiveLinkStyles} onPress={() => setDoLogout(true)}>
            Log Out
          </Link>
        </div>
      </li>
      <li className="app-nav__menu__item">
        <div className="app-nav__menu__item__a">
          <Link
            style={inactiveLinkStyles}
            href="https://smartrentmanager.zendesk.com/hc/en-us/sections/360003524214-Release-Notes"
            target="_blank"
            rel="noreferrer"
          >
            Latest Updates
          </Link>
        </div>
      </li>
      <li className="app-nav__menu__item" ref={helpRef}>
        <div className="app-nav__menu__item__a">
          <Link
            style={inactiveLinkStyles}
            onPress={() => setShowHelp(!showHelp)}
          >
            Help
          </Link>
        </div>
        {showHelp ? (
          <View
            ref={helpMenuRef}
            style={
              isMobile
                ? [styles.helpNavMobile, { backgroundColor: colors.gray200 }]
                : [styles.helpNavDesktop, { backgroundColor: colors.gray900 }]
            }
          >
            {config?.manager_help_url ? (
              <a
                className="app-nav__dropdown__content"
                href={config.manager_help_url}
                target="_blank"
                onClick={() => setShowHelp(false)}
                rel="noreferrer"
              >
                SmartRent Manager Support
              </a>
            ) : null}
            <a
              className="app-nav__dropdown__content"
              href="https://smartrent.com/support/"
              target="_blank"
              onClick={() => setShowHelp(false)}
              rel="noreferrer"
            >
              Resident Support
            </a>
          </View>
        ) : null}
      </li>
    </ul>
  );
};

const GlobalSearchTrigger = () => {
  const { colors, spacing, rounding } = useTheme();

  const globalSearchProps = useGlobalSearch();

  return (
    <Pressable
      style={{
        backgroundColor: colors.inputBackground,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingHorizontal: spacing.sm,
        borderRadius: rounding.raisedSurface,
        height: 40,
        width: 165,
      }}
      onPress={globalSearchProps.onOpen}
    >
      <Search />
      <GlobalSearch {...globalSearchProps} />
    </Pressable>
  );
};

export const TopNav = () => {
  const { colors } = useTheme();
  const location = useLocation();
  const user = useCurrentUser();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { data: csFeatureFlag } = useFeatureFlagEnabled({
    flag: "central_station",
    organizationId: user?.organization?.id,
  });

  const showSearchBox =
    location.pathname.includes("/groups/") ||
    location.pathname.includes("/units/");

  useEffect(() => {
    setShowMobileNav(false);
  }, [setShowMobileNav, location.pathname]);

  const showDemoOrgLogo = user?.organization?.is_demo ?? false;

  return (
    <>
      <header className="app__header2 app-header" role="banner">
        <div className="app-header__main">
          {csFeatureFlag?.enabled && user?.is_smartrent_staff ? (
            <div className="app-header__main__cs">
              <NineSquareButton
                fetchApps={fetchApps}
                onPressApp={onPressApp}
                onPressViewAll={onPressApp}
                viewAllInNewTab={true}
              />
            </div>
          ) : null}
          <h1 className="app-header__main__brandmark">
            <Link to={links.home}>
              <img
                className="app-header__main__brandmark__img"
                src={
                  showDemoOrgLogo
                    ? "/images/brandmark-secondary-primary-demo.svg"
                    : "/images/brandmark-secondary-primary.svg"
                }
                alt="SmartRent"
              />
            </Link>
          </h1>
          <nav
            className="app-header__app-nav app-nav"
            role="navigation"
            aria-label="Main Navigation"
          >
            <button
              className="app-nav__navicon"
              type="button"
              onClick={() => setShowMobileNav(!showMobileNav)}
            >
              <span className="app-nav__navicon__layout">
                <Hamburger size={20} color={colors.primary} />
              </span>
              <span className="u-hidden-visually">Toggle App Navigation</span>
            </button>
            <NavItems isMobile={false} showMobileNav={false} />
          </nav>
        </div>
        <div className="app-header__sub">
          {showSearchBox ? <GlobalSearchTrigger /> : null}
        </div>
      </header>
      <NavItems isMobile={true} showMobileNav={showMobileNav} />
    </>
  );
};

const styles = StyleSheet.create({
  link: {
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 14,
  },
  mobileLink: {
    fontSize: 18,
  },
  helpNavMobile: {
    zIndex: 3,
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingLeft: 12,
  },
  helpNavDesktop: {
    zIndex: 3,
    flexDirection: "column",
    position: "absolute",
    minWidth: "fit-content",
    borderRadius: 4,
    justifyContent: "flex-start",
    top: 35,
  },
});
